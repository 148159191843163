import { useMemo } from 'react';

const useBaseRoute = (
  pathname: string,
): { baseRoute: string; isBase: boolean } => {
  const baseRouteData = useMemo(() => {
    const segments = pathname.split('/').filter(Boolean);
    const baseRoute = segments[0] || '';
    const isBase = segments.length <= 1;
    return { baseRoute, isBase };
  }, [pathname]);

  return baseRouteData;
};

export default useBaseRoute;
