import React, { useState } from 'react';
import { useParams } from 'react-router';
import { AlertCircle } from '@saleshandy/icons';
import { Button } from '@saleshandy/design-system';
import UpgradePlanErrorModal from '../../../../../shared/components/upgrade-plan-error-modal';
import DropDownAtom from '../../../../../shared/design-system/components/atoms/dropdown';
import Icon from '../../../../../shared/design-system/components/atoms/icon/icon';
import AddToSequenceReportModal from '../../../../../shared/design-system/components/atoms/report-modal';
import Alert from '../../../../../shared/design-system/ui/alert';
import { ResourceIdentifiers } from '../../../../../shared/utils/access-control/enums/resource-identifiers';
import hasResource from '../../../../../shared/utils/access-control/has-resource';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';
import ImportContactsModal from '../../../components/sequence-single-content/components/sequence-single-contacts/components/import-contacts-modal';
import SearchAndAddModalWrapper from '../../../components/sequence-single-content/components/sequence-single-contacts/components/search-and-add-modal';
import { SequenceButtonContactsProps } from './types';
import { isUserOnStarterPlan } from './utils/helper';
import {
  getRestrictionPlanModalBodyContent,
  getRestrictionPlanWarningEmailBody,
} from '../../../../prospect/components/prospect-list/utils/helper';
import RestrictionErrorModal from '../../../../prospect/components/prospect-list/components/modals/restriction-error-modal';
import { getSequenceConfig } from '../../../helpers/sequence.api';
import { SequenceSettingCode } from '../../../enums';
import { riskyProspectWarningconstants } from '../../../components/sequence-single-content/components/sequence-single-contacts/components/email-verification/risky-prospect-constant';
import { reportConstants } from '../../../../../shared/design-system/components/atoms/report-page/constants/report-constants';
import { ProductTour } from '../../../../../shared/enums/product-tour';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import { getProspectParams } from '../../../../prospect/helpers/prospect-filters-resolver';
import { ContactListParams } from '../../../types';
import { constants } from '../../../../../shared/enums/constants';
import { ProfileProgressSteps } from '../../../../../shared/types/user-setting';
import {
  isFreeArTrailPlan,
  isFreePlan,
} from '../../../../../shared/utils/plans';
import planPermissionError from '../../../../../shared/utils/errors/plan-permission-error';

const AddContactsButton = ({
  isStepsListEmpty,
  fromModule,
  onTabChange,
  sendGetContactStatusCountRequest,
  sendGetSequenceContactListRequest,
  resetSequenceContacts,
  sendGetEmailVerificationCreditsRequest,
  subscriptionPlanType,
  remainingProspectLimit,
  sequenceProspectsFilters,
  setSequenceProspectsFilters,
  isDropDown = true,
  profileProgress,
  updateProfileProgressRequest,
  getSequenceStatsCountRequest,
  t,
}: SequenceButtonContactsProps) => {
  const [showImportContactsModal, setShowImportContactsModal] = useState(false);
  const [showSearchAndAddModal, setShowSearchAndAddModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [
    { successful, failed, resultData },
    setSearchAndAddModalResult,
  ] = useState({
    successful: 0,
    failed: 0,
    resultData: null,
  });
  const { sequenceId } = useParams<{ sequenceId: string }>();

  const [showUpgradePlanErrorModal, setShowUpgradePlanErrorModal] = useState(
    false,
  );
  const [
    isRestrictionModalVisible,
    setIsRestrictionModalVisible,
  ] = useState<boolean>(false);
  const [warning, setWarning] = useState('');

  //* Show Hide Restriction Error Modal
  const showRestrictionErrorModal = () => setIsRestrictionModalVisible(true);
  const hideRestrictionErrorModal = () => setIsRestrictionModalVisible(false);

  const callSequenceConfigApi = (seqId) =>
    getSequenceConfig(seqId)
      .then((configData) => configData)
      .catch((error) => {
        console.error('Error fetching config:', error);
        throw error;
      });

  const getRiskyWarning = async () => {
    if (fromModule.toString() === 'sequence') {
      try {
        const configData = await callSequenceConfigApi(sequenceId);
        const trackRiskyProspects =
          configData?.payload?.settings?.find(
            (setting) =>
              setting.code === SequenceSettingCode.TRACK_RISKY_PROSPECTS,
          )?.value === '1';

        if (trackRiskyProspects) {
          return riskyProspectWarningconstants.INCLUDE_RISKY_PROSPECT_IN_SEQUENCE;
        }
        return riskyProspectWarningconstants.NOT_INCLUDE_RISKY_PROSPECT_IN_SEQUENCE;
      } catch (error) {
        console.error('Error fetching config:', error);
        return riskyProspectWarningconstants.PROSPECT_DEFAULT_WARNING;
      }
    } else {
      return riskyProspectWarningconstants.PROSPECT_DEFAULT_WARNING;
    }
  };

  const fetchData = async () => {
    try {
      const riskyWarning = await getRiskyWarning();
      setWarning(riskyWarning);
    } catch (error) {
      console.error('Error fetching risky warning:', error);
    }
  };

  const onHandleDropdownClick = (option: string) => {
    if (isFreePlan()) {
      const title = 'Please upgrade your plan to start adding prospects';
      const content = [
        'Your account is downgraded to a free plan. Upgrade to paid plan to add prospects and resume email sending.',
      ];

      planPermissionError.showModal(title, content, true);
      return;
    }

    switch (option) {
      case 'SEARCH':
        if (isUserOnStarterPlan(subscriptionPlanType)) {
          setShowUpgradePlanErrorModal(true);
          return;
        }

        hasResource(ResourceIdentifiers.SEQUENCES_CONTACTS_IMPORT) &&
          setShowSearchAndAddModal(true);
        break;
      case 'IMPORT':
        if (remainingProspectLimit === 0) {
          showRestrictionErrorModal();
          return;
        }
        if (isUserOnStarterPlan(subscriptionPlanType)) {
          setShowUpgradePlanErrorModal(true);
          return;
        }

        fetchData();
        hasResource(ResourceIdentifiers.SEQUENCES_CONTACTS_IMPORT) &&
          setShowImportContactsModal(true);
        sendGetEmailVerificationCreditsRequest();
        break;
      default:
        break;
    }
  };

  const options = [
    {
      listKey: 'IMPORT',
      icon: 'software-download',
      onClick: onHandleDropdownClick,
      listLabel: `Import via CSV`,
      opId: ProductTour.ImportViaCSV,
    },
    hasPermission(Permissions.PROSPECT_READ) && {
      listKey: 'SEARCH',
      icon: 'math-plus',
      onClick: onHandleDropdownClick,
      listLabel: `Search & Add`,
    },
  ].filter((opt) => opt);

  let addContactsButton = isDropDown ? (
    <DropDownAtom
      id={ProductTour.AddProspect}
      heading="Add Prospects"
      disabled={isStepsListEmpty}
      options={options}
    />
  ) : (
    <div className="d-flex gap-12">
      {options.map((option) => (
        <Button
          variant={option.listKey === 'IMPORT' ? 'primary' : 'secondary'}
          onClick={() => option.onClick(option.listKey)}
          disabled={isStepsListEmpty}
        >
          <Icon
            identifier={option.listKey === 'IMPORT' ? option.icon : 'search'}
          />
          <span className=" ml-2">{option.listLabel}</span>
        </Button>
      ))}
    </div>
  );

  if (isStepsListEmpty) {
    addContactsButton = (
      <>
        <span>{addContactsButton}</span>
        <br />
        <span>
          <strong>Create at-least 1 step to add prospects.</strong>
        </span>
        <p className="go-to-link">
          <strong>
            Go to{' '}
            <span {...accessibleOnClick(() => onTabChange('steps'))}>
              Steps
            </span>
          </strong>
        </p>
      </>
    );
  }

  const closeShowSearchAndAddModal = () => {
    setShowSearchAndAddModal(false);
  };

  const addProspectStepForGettingStarted = () => {
    if (profileProgress) {
      const addProspectStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName ===
          ProfileProgressSteps.AddProspects,
      );

      if (!addProspectStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.AddProspects,
          isCompleted: true,
        });
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSearchAndAdd = ({ successful, failed, resultData }) => {
    closeShowSearchAndAddModal();
    setShowReportModal(true);
    setSearchAndAddModalResult({ successful, failed, resultData });

    addProspectStepForGettingStarted();
    getSequenceStatsCountRequest(Number(sequenceId));
  };

  const hideReportModal = () => {
    setShowReportModal(false);
    resetSequenceContacts();

    const filters = {
      ...sequenceProspectsFilters,
      status: sequenceProspectsFilters.status.filter(
        (item) => !item?.isHeaderFilter,
      ),
    };

    setSequenceProspectsFilters(filters);

    const payload = getProspectParams(
      {
        pageNum: constants.DEFAULT_PAGE_NUM,
        pageSize: constants.DEFAULT_PAGE_SIZE,
      },
      filters,
    );
    sendGetSequenceContactListRequest(sequenceId, payload as ContactListParams);
    sendGetContactStatusCountRequest(sequenceId, payload as ContactListParams);
  };

  const unsubscribeWarningOption = (
    <Alert
      variant="alert"
      description={t('messages.warning_cannot_add_to_sequence')}
      icon={AlertCircle}
    />
  );

  const reportDataStructure = {
    heading: reportConstants.ADD_PROSPECT_HEADING,
    sections: [
      {
        topic: reportConstants.SUCCESSFULLY_MOVED,
        value: successful,
        icon: <Icon identifier="check-o" className="lime-txt-7" />,
      },
      {
        topic: reportConstants.FAILED_TO_MOVE,
        value: failed,
        icon: <Icon identifier="danger" className="gold-txt-6" />,
      },
    ],
    reportData: {
      failedResultData: resultData,
    },
  };

  return (
    <>
      <div>{addContactsButton}</div>
      {showImportContactsModal && (
        <ImportContactsModal
          warning={warning}
          show={showImportContactsModal}
          onClose={() => setShowImportContactsModal(false)}
          fromModule={fromModule}
        />
      )}
      {showSearchAndAddModal && (
        <SearchAndAddModalWrapper
          showSearchAndAddModal={showSearchAndAddModal}
          closeModal={closeShowSearchAndAddModal}
          onSubmit={onSearchAndAdd}
          showRestrictionErrorModal={showRestrictionErrorModal}
        />
      )}
      {showReportModal && (
        <>
          <AddToSequenceReportModal
            show={showReportModal}
            onHide={hideReportModal}
            modalTitle="Search & Add Prospect(s)"
            reportDataStructure={reportDataStructure}
            infoDescription={unsubscribeWarningOption}
            showContent={true}
          />
        </>
      )}
      {showUpgradePlanErrorModal && (
        <UpgradePlanErrorModal
          show={showUpgradePlanErrorModal}
          onClose={() => setShowUpgradePlanErrorModal(false)}
          planName="starter"
          restrictionOn="prospect"
        />
      )}

      {isRestrictionModalVisible && (
        <RestrictionErrorModal
          show={isRestrictionModalVisible}
          onClose={hideRestrictionErrorModal}
          bodyContent={getRestrictionPlanModalBodyContent()}
          emailBody={getRestrictionPlanWarningEmailBody()}
        />
      )}
    </>
  );
};

export default AddContactsButton;
