import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { MyProfile } from '../../types/my-profile';
import {
  getMyProfileRequest,
  resendOtpForChangeEmailRequest,
  sendOtpForChangeEmailRequest,
  updateMyProfileRequest,
  updatePasswordRequest,
  verifyCurrentPasswordRequest,
  verifyOtpForChangeEmailRequest,
  deleteMyProfileRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  getUserDetailRequest: RequestState;
  updateMyProfileRequest: RequestState;
  updatePasswordRequest: RequestState;
  verifyCurrentPasswordRequest: RequestState;
  sendOtpForChangeEmailRequest: RequestState;
  resendOtpForChangeEmailRequest: RequestState;
  verifyOtpForChangeEmailRequest: RequestState;
  deleteMyProfileRequest: RequestState;
  myProfile: MyProfile;
  isCurrentPasswordValid: boolean;
  canResendChangeEmailVerificationCode: boolean;
  isLoading: boolean;
};

const initialState: State = {
  getUserDetailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateMyProfileRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updatePasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyCurrentPasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  sendOtpForChangeEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resendOtpForChangeEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyOtpForChangeEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteMyProfileRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  myProfile: {
    firstName: null,
    lastName: null,
    timeZone: null,
    email: null,
  },
  isCurrentPasswordValid: true,
  canResendChangeEmailVerificationCode: true,
  isLoading: false,
};

const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState,
  reducers: {
    resetVerifyCurrentPasswordRequest: (state) => {
      state.verifyCurrentPasswordRequest =
        initialState.verifyCurrentPasswordRequest;
    },
    resetIsCurrentPasswordValid: (state) => {
      state.isCurrentPasswordValid = initialState.isCurrentPasswordValid;
    },
    resetSendOtpForChangeEmailRequest: (state) => {
      state.sendOtpForChangeEmailRequest =
        initialState.sendOtpForChangeEmailRequest;
    },
    resetResendOtpForChangeEmailRequest: (state) => {
      state.resendOtpForChangeEmailRequest =
        initialState.resendOtpForChangeEmailRequest;
    },
    resetVerifyOtpForChangeEmailRequest: (state) => {
      state.verifyOtpForChangeEmailRequest =
        initialState.verifyOtpForChangeEmailRequest;
    },
    resetDeleteMyProfileRequest: (state) => {
      state.deleteMyProfileRequest = initialState.deleteMyProfileRequest;
    },
  },
  extraReducers: (builder) => {
    // Get My profile
    builder.addCase(getMyProfileRequest.pending, (state) => {
      state.getUserDetailRequest.status = RequestStatus.Pending;
      state.getUserDetailRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getMyProfileRequest.fulfilled, (state, action) => {
      state.getUserDetailRequest.status = RequestStatus.Succeeded;
      state.getUserDetailRequest.message = action.payload.message;
      state.myProfile = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getMyProfileRequest.rejected, (state, action) => {
      state.getUserDetailRequest.status = RequestStatus.Failed;
      state.getUserDetailRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update My profile
    builder.addCase(updateMyProfileRequest.pending, (state) => {
      state.updateMyProfileRequest.status = RequestStatus.Pending;
      state.updateMyProfileRequest.error = null;
    });
    builder.addCase(updateMyProfileRequest.fulfilled, (state, action) => {
      state.updateMyProfileRequest.status = RequestStatus.Succeeded;
      state.updateMyProfileRequest.message = action.payload.message;
      state.myProfile = action.payload.payload;
    });
    builder.addCase(updateMyProfileRequest.rejected, (state, action) => {
      state.updateMyProfileRequest.status = RequestStatus.Failed;
      state.updateMyProfileRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Password
    builder.addCase(updatePasswordRequest.pending, (state) => {
      state.updatePasswordRequest.status = RequestStatus.Pending;
      state.updatePasswordRequest.error = null;
    });
    builder.addCase(updatePasswordRequest.fulfilled, (state, action) => {
      state.updatePasswordRequest.status = RequestStatus.Succeeded;
      state.updatePasswordRequest.message = action.payload.message;
    });
    builder.addCase(updatePasswordRequest.rejected, (state, action) => {
      state.updatePasswordRequest.status = RequestStatus.Failed;
      state.updatePasswordRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Verify Current Password
    builder.addCase(verifyCurrentPasswordRequest.pending, (state) => {
      state.verifyCurrentPasswordRequest.status = RequestStatus.Pending;
      state.verifyCurrentPasswordRequest.error = null;
    });
    builder.addCase(verifyCurrentPasswordRequest.fulfilled, (state, action) => {
      state.verifyCurrentPasswordRequest.status = RequestStatus.Succeeded;
      state.verifyCurrentPasswordRequest.message = action.payload.message;
      state.isCurrentPasswordValid = action.payload.payload;
    });
    builder.addCase(verifyCurrentPasswordRequest.rejected, (state, action) => {
      state.verifyCurrentPasswordRequest.status = RequestStatus.Failed;
      state.verifyCurrentPasswordRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Send OTP To Change Email
    builder.addCase(sendOtpForChangeEmailRequest.pending, (state) => {
      state.sendOtpForChangeEmailRequest.status = RequestStatus.Pending;
      state.sendOtpForChangeEmailRequest.error = null;
    });
    builder.addCase(sendOtpForChangeEmailRequest.fulfilled, (state, action) => {
      state.sendOtpForChangeEmailRequest.status = RequestStatus.Succeeded;
      state.sendOtpForChangeEmailRequest.message = action.payload.message;
    });
    builder.addCase(sendOtpForChangeEmailRequest.rejected, (state, action) => {
      state.sendOtpForChangeEmailRequest.status = RequestStatus.Failed;
      state.sendOtpForChangeEmailRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Resend OTP To Change Email
    builder.addCase(resendOtpForChangeEmailRequest.pending, (state) => {
      state.resendOtpForChangeEmailRequest.status = RequestStatus.Pending;
      state.resendOtpForChangeEmailRequest.error = null;
    });
    builder.addCase(
      resendOtpForChangeEmailRequest.fulfilled,
      (state, action) => {
        state.resendOtpForChangeEmailRequest.status = RequestStatus.Succeeded;
        state.resendOtpForChangeEmailRequest.message = action.payload.message;
        state.canResendChangeEmailVerificationCode =
          action.payload.payload.canResendChangeEmailVerificationCode;
      },
    );
    builder.addCase(
      resendOtpForChangeEmailRequest.rejected,
      (state, action) => {
        state.resendOtpForChangeEmailRequest.status = RequestStatus.Failed;
        state.resendOtpForChangeEmailRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Verify OTP To Change Email
    builder.addCase(verifyOtpForChangeEmailRequest.pending, (state) => {
      state.verifyOtpForChangeEmailRequest.status = RequestStatus.Pending;
      state.verifyOtpForChangeEmailRequest.error = null;
    });
    builder.addCase(
      verifyOtpForChangeEmailRequest.fulfilled,
      (state, action) => {
        state.verifyOtpForChangeEmailRequest.status = RequestStatus.Succeeded;
        state.verifyOtpForChangeEmailRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      verifyOtpForChangeEmailRequest.rejected,
      (state, action) => {
        state.verifyOtpForChangeEmailRequest.status = RequestStatus.Failed;
        state.verifyOtpForChangeEmailRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete Account
    builder.addCase(deleteMyProfileRequest.pending, (state) => {
      state.deleteMyProfileRequest.status = RequestStatus.Pending;
      state.deleteMyProfileRequest.error = null;
    });
    builder.addCase(deleteMyProfileRequest.fulfilled, (state, action) => {
      state.deleteMyProfileRequest.status = RequestStatus.Succeeded;
      state.deleteMyProfileRequest.message = action.payload.message;
    });
    builder.addCase(deleteMyProfileRequest.rejected, (state, action) => {
      state.deleteMyProfileRequest.status = RequestStatus.Failed;
      state.deleteMyProfileRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetVerifyCurrentPasswordRequest,
  resetIsCurrentPasswordValid,
  resetSendOtpForChangeEmailRequest,
  resetResendOtpForChangeEmailRequest,
  resetVerifyOtpForChangeEmailRequest,
  resetDeleteMyProfileRequest,
} = myProfileSlice.actions;
export { getMyProfileRequest, updateMyProfileRequest, updatePasswordRequest };
export default myProfileSlice.reducer;
